<template>
    <div class="ui-core-layout-column">
        <div class="grid-wrapper" :class="columnClasses" :style="gridWrapperStyles">
            <template v-for="slot in blok.slots" :key="slot._uid">
                <Slot :layout-slot="slot" :has-custom-item-per-row="blok.customRowItems" />
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ICoreLayout } from '~ui/types/components/CoreLayout';
import Slot from '~ui/components/CoreLayout/Slot.vue';

const { blok } = defineProps<{ blok: ICoreLayout }>();

const gridWrapperStyles = computed(() => ({
    '--items-desktop': blok.customRowItems ? blok.desktopItems : null,
    '--items-tablet': blok.customRowItems ? blok.tabletItems : null,
    '--items-mobile': blok.customRowItems ? blok.mobileItems : null,
    '--column-gap': blok.gaps ? `${blok.gaps}px` : `1rem`,
}));
const columnClasses = computed(() => ({
    breakpoints: blok.customRowItems || null,
}));
</script>

<style scoped lang="postcss">
.grid-wrapper {
    @apply grid grid-cols-1 md:grid-cols-12 gap-[var(--column-gap)];

    &.breakpoints {
        @apply grid-cols-[repeat(var(--items-mobile),1fr)]
        md:grid-cols-[repeat(var(--items-tablet),1fr)]
        lg:grid-cols-[repeat(var(--items-desktop),1fr)];
        grid-template-rows: 1fr;
    }
}
.slot:has(> .table) {
    overflow-x: auto;
}
</style>
